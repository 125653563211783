import intlTelInput from 'intl-tel-input';

export function phoneInput(){
	const phoneInputs = document.querySelectorAll('[name*="Phone"],[name*="phone"]');

	window.ph = intlTelInput;

	phoneInputs.forEach(function(el, index){

		var iti = intlTelInput(el, {
	        preferredCountries: ['gb','ie','au','us','fr'],
	        initialCountry: 'gb',
	        separateDialCode: true,
	        hiddenInput: false // we do this manually or it tries to be too smart
	    });

	    el.insertAdjacentHTML('afterend','<input type="hidden" name="'+el.getAttribute('name')+'"/>');

	    el.setAttribute('name','temp_phone');

    	_updateHiddenInput(el, iti);

	    el.addEventListener('countrychange', function() {
	    	_updateHiddenInput(el, iti);
	    });

	    el.addEventListener('keyup', function(){
	    	_updateHiddenInput(el, iti);
	    });
	});

	function _updateHiddenInput(el, iti){
	    var hiddenInput = el.parentNode.querySelector('input[type="hidden"]');
		var selectedInfo = iti.getSelectedCountryData();
    	var selectedCode = selectedInfo.dialCode;

    	if(el.value == ""){
    		hiddenInput.value = "";
    	}else{
    		hiddenInput.value = '+' + selectedCode + el.value;
		}
	}
}