export function mobileHeader() {
    var headerItems = document.querySelectorAll('.menu-item-has-children .subnav-trigger') 

    if( !headerItems.length ) return;
    
    headerItems.forEach(function(el, index){
        el.addEventListener('click', function(e) { 
            e.preventDefault();
            el.closest('li').classList.toggle('subnav-open');
        });
    });
}

export function menuHovers() {
    var headerAnchors = document.querySelectorAll('.nav li a'); 

    if( !headerAnchors.length ){
        return;
    }

    for(var i = 0; i < headerAnchors.length; i++) {
        headerAnchors[i].setAttribute('title', headerAnchors[i].textContent);
    }
}

export function hamburger() {
    $('.site-header__hamburger').on('click', function(){
        $('body').toggleClass('menu-open');
    });
}

export function stickyHeader() {
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 20) {
            $('.site-header').addClass('scrolled');
        } else {
            $('.site-header').removeClass('scrolled');
        }
    });
}