import './vendor/jquery.viewportchecker.min.js';
import './vendor/micromodal.min.js';
import './vendor/jquery.easing.min.js';

import {swiperDesktop, swiperMobile} from './components/swiper';
import {hamburger, mobileHeader, stickyHeader, menuHovers} from './components/header';
import {popups} from './components/popups';
import { viewAnimation, smoothScroll } from "./util/animation";
import {outputMonth} from './util/dates';
import {phoneConcat, readMore} from './util/text';
import {cf7Redirect} from './util/window';
import {exitIntent} from './util/exitintent';
import {phoneInput} from './util/phoneinput';
import {podcasts} from './util/podcasts';
import {blogs, iframes} from './util/blogs';
import {accordions} from './util/accordions';
import {inlineVideo} from './util/inlinevideo';
import {initTimer} from './components/timer';
import {multistep} from './components/multi-step-form.js'


jQuery(document).ready(function($){
    multistep();
    mobileHeader();
    menuHovers();
    stickyHeader();
    hamburger();
    outputMonth();
    viewAnimation();
    swiperDesktop();
    swiperMobile();
    smoothScroll();
    phoneConcat();
    popups();
    exitIntent();
    readMore();
    podcasts();
    blogs();
    iframes();
    accordions();
    initTimer();
    inlineVideo();
    phoneInput();
});
