export function viewAnimation () {
  // Components loading animations
  $('.view-animation').viewportChecker({
    classToAdd: 'animated',
    offset: 20
  })
}


export function smoothScroll() {
  $(document).on('click', 'a[href*="#"]:not([href*="#popup"])', function (event) {
    event.preventDefault()
    var target = $(this).attr('href');

    if ($(target).length) {
        $('html, body').animate({
            scrollTop: $(target).offset().top - $('.site-header').outerHeight()
        }, 1500)
    }
});
}
