export function accordions() {
    var accordionEls = document.querySelectorAll('.accordion__title');

    if(!accordionEls.length){
        return;
    }

    const slideDown = elem => elem.style.height = `${elem.scrollHeight}px`;
    const slideUp = elem => elem.style.height = `0px`;

    accordionEls.forEach(function(el, index){
        el.addEventListener('click', function(){

            if(el.parentNode.classList.contains('active')){
                slideUp(el.nextElementSibling);
                el.parentNode.classList.remove('active');
            }else{
                _hideAll();
                el.parentNode.classList.add('active');
                slideDown(el.nextElementSibling);

                setTimeout(function(){

                    var headerOffset = document.querySelector('.site-header').getBoundingClientRect().height;
                    var elementPosition = el.getBoundingClientRect().top;
                    var offsetPosition = elementPosition + window.scrollY - headerOffset - 20;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });
                }, 600);
            }
        });
    });

    // open #1
    accordionEls[0].click();

    function _hideAll(){
        accordionEls.forEach(function(el, index){
            slideUp(el.nextElementSibling);
            el.parentNode.classList.remove('active');
        });
    }
}