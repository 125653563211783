export function initTimer(){
	var timerEls = document.querySelectorAll('.kk-timer');
	var minsEls = document.querySelectorAll('.mins');
	var secsEls = document.querySelectorAll('.secs');

	if(!timerEls.length){
		return;
	}

	var expiry_date = roundToNearest15();
	var now = new Date().getTime() / 1000;

	if(expiry_date - now <= 0){
		return;
	}

	if(!expiry_date){
		return;
	}

	var counter = setInterval(timer, 1000); //1000 will run it every 1 second


	function timer(){
		var output = '';
		var now = new Date().getTime() / 1000;

		var days_left = 0;
		var hours_left = 0;
		var mins_left = 0;
		var secs_left = 0;

		// get days left til expiry
		days_left = (expiry_date - now) / 60 / 60 / 24;
		hours_left = (days_left - Math.floor(days_left)) * 24;
		mins_left = (hours_left - Math.floor(hours_left)) * 60;
		secs_left = (mins_left - Math.floor(mins_left)) * 60;

		_updateMins(mins_left);
		_updateSecs(secs_left);
	}

	function _updateMins(mins_left){
		minsEls.forEach(function(el, index){
			if(Math.floor(mins_left) == 1){
				el.classList.add('singular');
			}else{
				el.classList.remove('singular');
			}

			el.innerHTML =  addLeadingZero(Math.floor(mins_left), el.classList.contains('no-zero'));
		});
	}

	function _updateSecs(secs_left){
		secsEls.forEach(function(el, index){

			if(Math.floor(secs_left) == 1){
				el.classList.add('singular');
			}else{
				el.classList.remove('singular');
			}

			el.innerHTML =  addLeadingZero(Math.floor(secs_left), el.classList.contains('no-zero'));
		});
	}
}

function addLeadingZero(val, ret){
	if(ret){
		return val;
	}

	if(parseInt(val) === 0){
		return '00';
	}
	if(parseInt(val) < 10){
		return '0'+val;
	}
	return val;
}

function roundToNearest15(date = new Date()) {
	const minutes = 15;
	const ms = 1000 * 60 * minutes;

	return new Date(Math.ceil(date.getTime() / ms) * ms).getTime() / 1000;
}