export function popups(){
	var videoModalTriggers = document.querySelectorAll('.modal-video-trigger');
	var formModalTriggers = document.querySelectorAll('[href*="popup-"]');

	var popups = document.querySelectorAll('.modal');
	popups.forEach(function(el, index){
		el.classList.add('ready');
	});	

	window.mmOnOpen = function(){
		document.documentElement.classList.add('modal-open');
	}

	window.mmOnClose = function(){
		document.documentElement.classList.remove('modal-open');
		pauseAllVideos();
	}
	
	MicroModal.init({
		onShow: window.mmOnOpen, 
		onClose: window.mmOnClose
	});

	videoModalTriggers.forEach(function(el, index){
		el.addEventListener('click', function(){
			var newVideoURL = '';
			var videoID = el.dataset.videoId;
			var thisModalID = el.dataset.micromodalTrigger;
			var thisModalEl = document.querySelector('#'+thisModalID);

			if(el.classList.contains('vimeo')){
                var iframeHTML = '<iframe src="https://player.vimeo.com/video/' + videoID + '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
            } else { 
                var iframeHTML = '<div class="wistia_embed wistia_async_'+videoID+' controlsVisibleOnLoad=false playerColor=#4AC186"></div>';
                _wq.push({ 
                    id: videoID, 
                    onReady: function(video) {
                    video.play();
                }});
            }

            if(thisModalEl){
            	var thisModalCont = thisModalEl.querySelector('.video-container');
            	thisModalCont.innerHTML = iframeHTML;
            }
		});
	});

	function pauseAllVideos(){
		var allModals = document.querySelectorAll('.modal--video');

		allModals.forEach(function(el, index){
			el.querySelector('.video-container').innerHTML = '';
		});
	}


	formModalTriggers.forEach(function(el, index){
		el.addEventListener('click', function(e){
			e.preventDefault();

			if(document.querySelector(el.getAttribute('href'))){
				MicroModal.show(el.getAttribute('href').replace('#',''), {
					onShow: window.mmOnOpen, 
					onClose: window.mmOnClose,
					disableFocus: true
				});
				
				var allQuestionnaires = document.querySelectorAll('.questionnaire-form fieldset');

				allQuestionnaires.forEach(function(el, index){
					setFormHeight(el);
				});
			}
		});
	});
}