export function outputMonth() {
        var months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        var now       = new Date();
        var thisMonth = months[now.getMonth()]; // getMonth method returns the month of the date (0-January :: 11-December)
        var output = document.getElementsByClassName('output');
    
        if( output.length ) {
    
            for (var i = 0; i < output.length; i++) {
                output[i].innerHTML = thisMonth;
            }
    
        }
}
