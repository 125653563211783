export function podcasts() {
    var pdcBtn = document.querySelector('.podcast-filter__title');

    if(!pdcBtn){
        return;
    }
    
    pdcBtn.addEventListener('click', function(){
        pdcBtn.parentNode.classList.toggle('active');
    });

    var loadmore = document.querySelector('.page-template-template-podcasts .btn-ajax');
    var appendToEl = document.querySelector('.ajax-append');

    loadmore.addEventListener('click', function(){
        loadmore.textContent = 'Loading...';

        var thisPage = loadmore.dataset.paged;
        var ppp = loadmore.dataset.ppp;

        loadmore.dataset.paged = parseInt(loadmore.dataset.paged) + 1;

        var data = new FormData();
        data.append("action", "more_podcasts");
        data.append("paged", thisPage);
        data.append("ppp", ppp);

        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
            if (this.readyState === 4 && this.status === 200) {
                var response = JSON.parse(this.responseText);

                if(response.html){
                    appendToEl.innerHTML += response.html;
                }

                if(response.nomore){
                    loadmore.parentNode.classList.add('d-none');
                }

                loadmore.textContent = 'Load More';
            }
        };

        xhr.open('POST', _ajaxurl);
        xhr.send(data);
    });
}